import React, { useRef, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { TypeAnimation } from 'react-type-animation';
import Logo from '../../assets/img/Logo2.svg';
import Form from '../Home/contact';
const ContactUs = () => {


    const containerRef = useRef(null);
    const titleText = "Reach out to us";

    useEffect(() => {
        const container = containerRef.current;
        const tempElement = document.createElement('div');
        tempElement.style.visibility = 'hidden';
        tempElement.innerHTML = titleText
        container.appendChild(tempElement);
        const lineHeight = parseFloat(window.getComputedStyle(tempElement).lineHeight);
        const totalHeight = tempElement.clientHeight;
        const calculatedLinesCount = Math.ceil(totalHeight / lineHeight);
        container.removeChild(tempElement);
        container.style.minHeight = calculatedLinesCount * 90 + 'px';
    }, []);



    return (
        <div className='contactus'>
            <div className='banner' >
                <div className='bannerContainer'>
                    <p className='pageTitle'>Contact us</p>
                    <TypeAnimation
                        ref={containerRef}
                        sequence={[
                            titleText,
                            3000,
                            ''
                        ]}
                        omitDeletionAnimation
                        wrapper="div"
                        cursor={true}
                        className='quote'
                        repeat={Infinity}
                    />
                </div>
            </div>
            <div className='content mt-100'>
                <img className='logo' src={Logo} alt="Logo" className='logo' />
                <div className='row'>
                    <div className='col-md-4 mt-50'>
                        <div className='header'>
                            Registered Office:
                        </div>
                        <div className='text'>
                            40/1, Arunachalam Street,
                        </div>
                        <div className='text'>
                            K.K. Pudur,
                        </div>
                        <div className='text'>
                            Coimbatore – 641 038.
                        </div>
                        <div className='header mt-25'>
                            Admin Office & Work:
                        </div>
                        <div className='text'>
                            1/407- A, Kothari Nagar,
                        </div>
                        <div className='text'>
                            K. Vadamadurai Post,
                        </div>
                        <div className='text'>
                            Coimbatore – 641 017.
                        </div>
                        <div className='d-flex mt-25 align-items-baseline gap-3'>
                            <div>
                                <div className='header d-flex'>
                                    <i className='bi bi-telephone-fill' />&nbsp;Phone
                                </div>
                            </div>
                            <div>
                                <div className='text'>
                                    <a href="tel:+914222461833">
                                        0422 – 2461833,
                                    </a>
                                </div>
                                <div className='text'>
                                    <a href="tel:+914222461899">
                                        0422 -  2461899.
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-25 align-items-baseline gap-3'>
                            <div>
                                <div className='header d-flex'>
                                    <i className='bi bi-envelope-fill' />&nbsp;Email&nbsp;
                                </div>
                            </div>
                            <div>
                                <div className='text'>
                                    <a href="mailto:mehacon@gmail.com">
                                        mehacon@gmail.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-25 align-items-baseline gap-3 icons'>
                            <i className="bi bi-facebook" />
                            <i className="bi bi-twitter" />
                            <i className="bi bi-instagram" />
                            <i className="bi bi-linkedin" />
                        </div>
                    </div>
                    <div className='col-md-8 mt-50'>
                        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1JnGGOlzcpmzhFqhBTgLzKBm3w33S2_Q&ehbc=2E312F&noprof=1"></iframe>
                    </div>
                </div>
                <div className='mt-100'>
                    <Form />
                </div>

            </div>
        </div >
    )



}

export default Navbar(Footer(ContactUs));
