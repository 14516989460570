import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {

    return (
        <section id="hero" className="hero">
            <img src={require("../../assets/img/hero.jpeg")} alt="" data-aos="fade-in" className="aos-init" />
            <div className="container">
                <div data-aos="fade-up" data-aos-delay="100" className="row aos-init">

                    <TypeAnimation
                        sequence={[
                            'Empower your operations with Mehacon gears. Ready for takeoff with cutting-edge mechanical solutions.',
                            3000,
                            'Manufacturing Gears and Solving Engineering Problems for the World, From Coimbatore.',
                            3000,
                            '',
                        ]}
                        omitDeletionAnimation
                        wrapper="h2"
                        cursor={true}
                        repeat={Infinity}
                    />
                </div>
            </div>
        </section>
    );
};

export default Hero;
