import React, { useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import { ReactComponent as NavbarLogo } from '../../assets/img/NavbarLogo.svg';
import { Link, useLocation } from 'react-router-dom';
import ROUTES from './routes';

const withNavbar = (WrappedComponent) => {

    return (props) => {

        const { HOME, ABOUTUS, PRODUCTS, CONTACTUS } = ROUTES;
        const location = useLocation();
        const isActive = (path) => location.pathname === path ? 'active' : '';

        useEffect(() => {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        }, [location.pathname]);


        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" fixed="top">
                    {isActive(HOME) ?
                        <Navbar.Brand as={ScrollLink} activeClass="active"
                            to="hero" smooth={true} duration={200}>
                            <NavbarLogo />
                        </Navbar.Brand> :
                        <Navbar.Brand as={Link} to={HOME}>
                            <NavbarLogo />
                        </Navbar.Brand>
                    }
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                        <Nav className="ml-auto">
                            <Nav.Link as={Link} to={ABOUTUS} className={isActive(ABOUTUS)}>About us</Nav.Link>
                            <Nav.Link as={Link} to={PRODUCTS} className={isActive(PRODUCTS)}>Products</Nav.Link>
                            <Nav.Link as={Link} to={CONTACTUS} className={isActive(CONTACTUS)}>Contact us</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <WrappedComponent {...props} />
            </div>
        );
    };
};

export default withNavbar;
