import React from 'react';
import Image1 from '../../assets/img/services/1.jpeg';

const Problem = () => {

    return (
        <section className="problem" id='services'>
            <div className='text-container'>
                <h2 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Your problem</h2>
                <h3 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Unique Challenges Demand Unique Solutions</h3>
                <p data-aos="fade-up" data-aos-delay="150" className='aos-init'>
                    Every industry faces its own set of challenges, often falling short of meeting these specialized requirements. Whether it's adapting to operational environments, integrating with existing systems, or achieving precise performance standards, your gear and gearbox needs are as unique as your business.
                </p>
                <h2 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Our solution</h2>
                <h3 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Custom Gearboxes: Tailored to your Needs</h3>
                <p data-aos="fade-up" data-aos-delay="150" className='aos-init'>
                    At Mehacon, we understand that one size does not fit all. That's why we offer custom-built gearboxes, meticulously designed and manufactured to your exact specifications using the latest technology and engineering expertise. From cooling towers and planetary gears for robotics to specialized material handling systems, our custom gearboxes serve a vast array of industries like Food Processing, Agriculture, Cement, and Mining, ensuring optimal performance tailored to your unique needs.
                </p>
                <h1 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Why Choose us?</h1>
                <h3 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Where Every Gear Turns with Innovation, Expertise, and Reliability
                </h3>
                <p data-aos="fade-up" data-aos-delay="150" className='aos-init'>
                    Mehacon stands at the forefront of gear manufacturing, driven by constant innovation and technological advancements. With 68+ years of experience, our expertise is evident in every precision-engineered product. We specialize in custom gear solutions, perfectly tailored to meet unique client requirements. Commitment to quality is paramount, with each product rigorously tested for durability and performance. Our global presence is balanced with dedicated local service, ensuring accessibility and support worldwide.
                </p>
                <div data-aos="fade-up" data-aos-delay="150" className='quote aos-init'>
                    "Empowering Partnerships, Engineering
                    Success - We gear up your aspirations
                    with our commitment to excellence."
                </div>
            </div>
            <div className='image-container aos-init' data-aos="fade-up" data-aos-delay="150">
                <img src={Image1} alt='' />
            </div>
        </section>
    );
};

export default Problem;
