import React from 'react';
import Hero from './hero';
import WhoWeAre from './whowerare';
import WhatWeDo from './whatwedo';
import Problem from './problem';
import Contant from './contact';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Home = () => {
    return (
        <div className='home'>
            <Hero />
            <WhoWeAre />
            <WhatWeDo />
            <Problem />
            <Contant />
        </div>
    );
};

export default Navbar(Footer(Home));
