import React, { useRef, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { Link } from 'react-scroll';
import ImageSrc1 from '../../assets/img/who-we-are/2.jpeg'
import ImageSrc2 from '../../assets/img/who-we-are/5.jpeg'
import ImageSrc3 from '../../assets/img/who-we-are/6.jpeg'
import { TypeAnimation } from 'react-type-animation';


const AboutUs = () => {

    const history = [
        {
            date: '1962',
            icon: 'bi bi-lightbulb-fill',
            title: 'Sri Thangamani industries starting',
            description: 'Thangamani Engineering Industries was founded by Mr. S. Ayyapparaj and Mr. S. Gopinath. The company started as a small workshop with a vision to revolutionize gearbox technology.'
        },
        {
            date: '19XX',
            icon: 'bi bi-water',
            title: 'Malampuzha dam story',
            description: 'The company was awarded a contract to supply gearboxes for the Malampuzha dam project, which was a significant milestone in our journey.'
        },
        {
            date: '200X',
            icon: 'bi bi-hammer',
            title: 'Tea, mining, Granite',
            description: 'We expanded our product range to include gearboxes for the tea, mining, and granite industries.'
        },
        {
            date: '201X',
            icon: 'bi bi-airplane-fill',
            title: 'HLL , HAL',
            description: 'We started supplying gearboxes to HLL and HAL, which further established our reputation as a reliable and quality-driven company.'
        },
        {
            date: '201X',
            icon: 'bi bi-balloon-fill',
            title: 'What now?',
            description: 'We are currently working on expanding our product range and exploring new markets.',
        }
    ]

    const navLinks = [
        {
            id: 'brandStory',
            name: 'Brand Story'
        },
        {
            id: 'visionary',
            name: 'Visionary'
        },
        // {
        //     id: 'history',
        //     name: 'History'
        // },
        {
            id: 'whyTheyLoveUs',
            name: 'Why they love us'
        },
        {
            id: 'customerFocus',
            name: 'Customer Focus'
        }
    ];

    const containerRef = useRef(null);
    const titleText = "Crafting Excellence in Every Turn";

    useEffect(() => {
        const container = containerRef.current;
        const tempElement = document.createElement('div');
        tempElement.style.visibility = 'hidden';
        tempElement.innerHTML = titleText
        container.appendChild(tempElement);
        const lineHeight = parseFloat(window.getComputedStyle(tempElement).lineHeight);
        const totalHeight = tempElement.clientHeight;
        const calculatedLinesCount = Math.ceil(totalHeight / lineHeight);
        container.removeChild(tempElement);
        container.style.minHeight = calculatedLinesCount * 90 + 'px';
    }, []);


    return (
        <div className='aboutus'>
            <div className='banner'>
                <div className='bannerContainer'>
                    <p className='pageTitle'>About Us</p>
                    <TypeAnimation
                        ref={containerRef}
                        sequence={[
                            titleText,
                            3000,
                            ''
                        ]}
                        omitDeletionAnimation
                        wrapper="div"
                        cursor={true}
                        className='quote'
                        repeat={Infinity}
                    />

                </div>
            </div>
            <div className='content'>
                <div className='row'>
                    <div className='col-md-3 mt-25'>
                        <div className='stickydiv'>
                            {navLinks.map((link, index) => {
                                return (
                                    <div className='nav' key={index}>
                                        <Link
                                            to={link.id}
                                            spy={true}
                                            smooth={true}
                                            duration={100}
                                            offset={-100}
                                        >
                                            {link.name}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='col-md-9 mt-25'>
                        <div className='header1'>
                            Welcome to Thangamani Engineering Industries, the epitome of engineering excellence in the gearbox manufacturing industry.
                        </div>
                        <div className='text1 mt-15'>
                            For over six decades, we have been at the forefront of designing and producing high-quality, durable, and innovative gearboxes for a diverse range of applications.
                        </div>
                        <img src={ImageSrc1} alt='brand' className='mt-50' />
                        <div className='row mt-70' id='brandStory'>
                            <div className='col-md-6 p-h-3' >
                                <div className='header-right mt-25'>
                                    Brand Story
                                </div>
                            </div>
                            <div className='col-md-6' >
                                <div className='text3 mt-25'>
                                    Mehacon gears and gearboxes, custom made products by Thangamani Engineering Industries.
                                </div>
                                <div className='text2 mt-15'>
                                    Founded in 1960, Thangamani Engineering Industries began as a small workshop with a vision to revolutionize gearbox technology. Today, we stand as a versatile company, delivering unparalleled solutions across various sectors including mining industries, granite industries, tea industries, and automation equipment.
                                </div>
                            </div>
                        </div>
                        <div className='row mt-70' id='visionary'>
                            <div className='col-md-6 p-h-3' >
                                <div className='header-right mt-25'>
                                    Visionary
                                </div>
                            </div>
                            <div className='col-md-6' >
                                <div className='text2 mt-15'>
                                    Under the visionary guidance of Mr. S. Ayyapparaj and Mr. S. Gopinath, our company has carved out a strong and respected position in this fiercely competitive field. Their immense knowledge in gears, along with their unwavering dedication to ethical business practices, acute strategic insights, and exceptional leadership skills have been pivotal in our success, enabling us to build an extensive and loyal client base across the nation.                                </div>
                            </div>
                            <div className='img-2 mt-50' >
                                <img src={ImageSrc2} alt='brand' />
                                <div className='text4 mt-25'>
                                    We pride ourselves on our ability to innovate. Our R&D department is constantly exploring new materials, designs, and manufacturing techniques to enhance the performance and sustainability of our products.
                                </div>
                            </div>
                        </div>
                        {/* <div className='mt-100' id='history'>
                            <div className='header-left mt-25'>
                                History
                            </div>
                            <div className='timeline mt-15'>
                                <div className="row mt-4">
                                    <div className="col-md-10 mx-auto">
                                        <div className="timeline timeline-four">
                                            {history.map((item, index) => {
                                                return (
                                                    <div className="timeline-item">
                                                        <div className="icon"></div>
                                                        <div className="date-content">
                                                            <div className="date-outer
                                                            "><span className="date"><i className={item.icon}></i> <span className="year mt-1">{item.date}</span></span></div>
                                                        </div>
                                                        <div className="timeline-content">
                                                            <h5 className="title">{item.title}</h5>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='mt-100' id='whyTheyLoveUs'>
                            <div className='header-left mt-25'>
                                Why they love us
                            </div>
                            <div className='quote mt-25 text-center'>
                                “Driving industries forward,
                                one gear at a time.”
                            </div>
                            <div className='img-2 mt-25'>
                                <img src={ImageSrc3} alt='brand' />
                            </div>
                            <div className='header1 mt-25'>
                                Innovation in every gear, excellence in every revolution
                            </div>
                            <div className='text1 mt-25'>
                                Our team consists of world-className engineers and skilled craftsmen who bring a blend of traditional methods and cutting-edge technology to the table. We specialize in custom gearbox solutions, ensuring that each product is tailored to meet the specific needs of our clients.
                            </div>
                            <div className='text1 mt-25'>
                                Quality is the cornerstone of everything we do at Thangamani Engineering Industries. Our gearboxes are renowned for their reliability, efficiency, and longevity. We achieve this through rigorous testing, continuous research and development, and adhering to international standards.
                            </div>
                        </div>

                        <div className='mt-100' id='customerFocus'>
                            <div className='header-left mt-25'>
                                Customer focus
                            </div>
                            <div className='text1 mt-25'>
                                Our clients are at the heart of our business. We believe in building strong relationships based on trust, transparency, and mutual respect. Our dedicated customer service team ensures that every client experience is seamless and satisfactory.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )



}

export default Navbar(Footer(AboutUs));
