import React, { useRef, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ImageSrc1 from '../../assets/img/who-we-are/7.jpeg'
import { TypeAnimation } from 'react-type-animation';
import ProductImage1 from '../../assets/img/what-we-do/1.jpg';
import ProductImage2 from '../../assets/img/what-we-do/2.jpg';
import ProductImage3 from '../../assets/img/what-we-do/3.jpg';
import ProductImage4 from '../../assets/img/what-we-do/4.jpg';
import ProductImage5 from '../../assets/img/what-we-do/5.jpg';
import ProductImage6 from '../../assets/img/what-we-do/6.jpg';
import Client1 from '../../assets/img/clients/1.jpg';
import Client2 from '../../assets/img/clients/2.jpg';
import Client3 from '../../assets/img/clients/3.jpg';
import Client4 from '../../assets/img/clients/4.jpg';
import Client5 from '../../assets/img/clients/5.jpg';
import Client6 from '../../assets/img/clients/6.jpg';
import Client7 from '../../assets/img/clients/7.jpg';
import Client8 from '../../assets/img/clients/8.jpg';
import Client9 from '../../assets/img/clients/9.jpg';
import Client10 from '../../assets/img/clients/10.jpg';


const Products = () => {

    const clients = [
        Client1, Client2, Client3, Client4, Client5, Client6, Client7, Client8, Client9, Client10
    ]

    const products = [
        {
            image: ProductImage1,
            title: 'Worm Reduction Gearboxes',
            description: 'Mehacon’s Worm Reduction Gearboxes stand out in the market for their exceptional torque handling and compact design, making them perfect for heavy-duty industrial use. Engineered with advanced metallurgy and precise worm gear technology, they offer high efficiency and low noise operation. Ideal for applications requiring variable speed and high load capacity, these gearboxes are the go-to solution for sectors like material handling, conveyors, and packaging machinery.'
        },
        {
            image: ProductImage2,
            title: 'Helical Reduction Gearboxes',
            description: 'Mehacon’s Helical Reduction Gearboxes are the epitome of engineering precision, designed for industries seeking high torque and compact power transmission solutions. These gearboxes, featuring advanced helical gears, offer smoother, quieter operation and increased durability compared to traditional gear systems. Their versatile design and ease of maintenance, makes them ideal for applications in sectors like mining, cement, steel, and power generation.'
        },
        {
            image: ProductImage3,
            title: 'Spur and Helical Gears (Hobbed)',
            description: 'Mehacon’s Hobbed Spur and Helical Gears are precision-engineered to power up your machinery. These gears are crafted using state-of-the-art hobbing techniques to ensure superior performance and longevity. Ideal for a variety of industrial applications, they offer smooth transmission, high durability, and excellent efficiency.'
        },
        {
            image: ProductImage5,
            title: 'Spur and Helical Gears (Ground)',
            description: 'Mehacon’s Ground Spur and Helical Gears elevate your industrial applications with precision and efficiency. Utilizing advanced grinding processes, these gears feature an exceptional surface finish and tight dimensional control, ensuring quiet operation and minimal vibration. Ideal for high-precision machinery, our ground gears provide the reliability and performance essential for cutting-edge applications.'
        },
        {
            image: ProductImage3,
            title: 'Chain Sprockets (Hobbed)',
            description: 'Mehacon’s Hobbed Chain Sprockets are precision-engineered for superior performance in a wide range of industrial applications. Manufactured with advanced hobbing technology, these sprockets ensure optimal engagement with chains, leading to smoother operation and extended lifespan of your machinery. Designed to withstand the rigorous demands of industries our chain sprockets offer a reliable and efficient solution for transmitting motion and power.'
        },
        {
            image: ProductImage6,
            title: 'Worm Wheels',
            description: 'Mehacon’s Worm Wheels are the cornerstone of precision and efficiency in worm gear systems, designed to meet rigorous demands. Crafted from high-quality materials using advanced manufacturing processes, these wheels ensure smooth and quiet operation with exceptional load-carrying capacity.Our worm wheels are ideal for applications requiring precise speed reduction and torque multiplication'
        },
        {
            image: ProductImage5,
            title: 'Worm Shafts (Profile Ground)',
            description: 'Mehacon’s Profile Ground Worm Shafts are the epitome of precision engineering, designed to deliver unparalleled efficiency and compatibility in worm gear systems. Manufactured with meticulous attention to detail, these shafts undergo a profile grinding process that ensures a high degree of surface finish and dimensional accuracy. This process enhances the shaft\'s performance, resulting in reduced friction, increased load capacity, and extended lifespan. Our worm shafts are ideal for applications requiring precise motion control and torque transmission.'
        },
        {
            image: ProductImage3,
            title: 'Worm Shafts (Precise-milled)',
            description: 'Mehacon’s Precision Milled Worm Shafts are tailored for applications demanding the utmost accuracy and efficiency. Crafted with state-of-the-art milling technology, these shafts feature exacting tolerances and a superior finish, ensuring optimal performance in worm gear assemblies. Designed for durability and smooth operation, they are ideal for a variety of industrial applications.'
        },
        {
            image: ProductImage4,
            title: 'Straight Bevel Gears',
            description: 'Mehacon’s Straight Bevel Gears are engineered for precision and durability, designed to meet the rigorous demands of mechanical systems requiring angular power transmission. These gears, with their straight teeth, are ideal for applications where reliability and efficiency are critical. Manufactured using advanced technology, they ensure smooth operation and a long service life.'
        },
        {
            image: ProductImage5,
            title: 'Spiral Bevel Gears',
            description: 'Mehacon’s Spiral Bevel Gears are at the forefront of engineering excellence, designed to handle complex power transmission with unparalleled smoothness and precision. These gears feature curved teeth, which engage gradually and smoothly, ensuring lower vibration and noise compared to straight bevel gears. Ideal for high-speed, high-torque, our spiral bevel gears offer superior durability and performance.'
        },
        {
            image: ProductImage3,
            title: 'Equipments for Mining',
            description: 'Mehacon’s Mining Equipment elevates your mining operations with specialized equipment, designed for precision, efficiency, and durability. Our Height Gauges ensure exact measurements for critical mining processes. Wire Rope and Capstan Winches provide robust solutions for lifting and positioning, ensuring safe and reliable load management. Twin Drum Drive Heads are engineered for powerful drilling operations, maximizing productivity. Signal Winches enhance underground communication, prioritizing safety. Step-Up Drives for Hydraulic Motors boost power transmission, optimizing equipment performance.'
        },
        {
            image: ProductImage5,
            title: 'Other Products',
            description: 'Mehacon’s custom-built special purpose gearboxes are designed to meet unique operational requirements with precision and durability. Our Timing Pulleys ensure synchronous motion and drive efficiency. Racks provide linear motion with utmost accuracy. Geared Couplings offer smooth torque transmission, enhancing alignment and reducing wear. Choose from Rigid & Flexible Couplings for a reliable connection between rotating elements. Trust our comprehensive selection of industrial spares to maintain and enhance the performance of your machinery, ensuring your operations run seamlessly and efficiently.'
        }
    ]

    const containerRef = useRef(null);
    const titleText = "Innovation in every gear, excellence in every revolution";

    useEffect(() => {
        const container = containerRef.current;
        const tempElement = document.createElement('div');
        tempElement.style.visibility = 'hidden';
        tempElement.innerHTML = titleText
        container.appendChild(tempElement);
        const lineHeight = parseFloat(window.getComputedStyle(tempElement).lineHeight);
        const totalHeight = tempElement.clientHeight;
        const calculatedLinesCount = Math.ceil(totalHeight / lineHeight);
        container.removeChild(tempElement);
        container.style.minHeight = calculatedLinesCount * 90 + 'px';
    }, []);



    return (
        <div className='products'>
            <div className='banner' >
                <div className='bannerContainer'>
                    <p className='pageTitle'>Products</p>
                    <TypeAnimation
                        ref={containerRef}
                        sequence={[
                            titleText,
                            3000,
                            ''
                        ]}
                        omitDeletionAnimation
                        wrapper="div"
                        cursor={true}
                        className='quote'
                        repeat={Infinity}
                    />
                </div>
            </div>
            <div className='content mt-50'>
                <img src={ImageSrc1} className='img' alt='...' />
            </div>
            <div className='content mt-50'>
                <div className='header-right mt-50'>
                    Our Clients
                </div>
                <div className='row client-wrapper justify-content-center mt-50'>
                    {clients.map((client, index) => {
                        return (
                            <div key={index} className='col-md-4 client'>
                                <img src={client} alt='...' />
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            <div className='content mt-100'>
                <div className='header-left mt-50'>
                    Our Products
                </div>
                <div className='text1 mt-15'>
                    At the heart of Mehacon’s offerings lies a diverse and innovative range of engineering solutions designed to meet the demands of modern industries. Our products embody our commitment to quality, efficiency, and reliability. Engineered for excellence, they ensure optimal performance across various applications. Explore our portfolio to find solutions that drive progress and success.
                </div>
                <div className='image-container mt-70 row justify-content-center aos-init' data-aos="fade-up" data-aos-delay="150">
                    {
                        products.map((product, index) => {
                            return (
                                <div key={index} className='col-md-4 pd-4 aos-init d-flex flex-column' data-aos="fade-up" data-aos-delay="150">
                                    <div className='image'>
                                        <img src={product.image || ProductImage1} alt='...' />
                                    </div>
                                    <div className='title mt-15'>
                                        {product.title}
                                    </div>
                                    <div className='description mt-15'>
                                        {product.description}
                                    </div>
                                </div>
                            )
                        }
                        )
                    }

                </div>
            </div>
        </div >
    )



}

export default Navbar(Footer(Products));
