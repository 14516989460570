import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image1 from '../../assets/img/who-we-are/1.jpeg';
import Image2 from '../../assets/img/who-we-are/2.jpeg';
import Image3 from '../../assets/img/who-we-are/3.jpeg';
import Image4 from '../../assets/img/who-we-are/4.jpeg';

const Home = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleNext = () => {
        if (index < 3) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }
    const handlePrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else {
            setIndex(3);
        }
    }

    const images = [Image1, Image2, Image3, Image4];
    return (
        <section className="who-we-are" id='about'>
            <div className='text-container'>
                <h2 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Who we are</h2>
                {/* <h3 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">
                    Thangamani Engineering Industry is A Legacy of Mechanical Excellence
                </h3> */}
                <h3 data-aos="fade-up" data-aos-delay="150" className="text-dark text-center aos-init">
                    From Coimbatore, we bring over 68 years of expertise, blending tradition with technology in our Mehacon Gears and Gearboxes.
                </h3>
            </div>
            <div className='carousel-main aos-init' data-aos-delay="100" data-aos="fade-in">
                <Carousel activeIndex={index}
                    onSelect={handleSelect}
                    controls={false}
                    slide={true}
                    indicators={false}
                >
                    {
                        images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img alt='' src={image} />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <div className='controller'>
                    <i className="bi bi-arrow-left-circle" onClick={() => handlePrev()}></i>
                    <i className="bi bi-arrow-right-circle" onClick={() => handleNext()}></i>
                </div>
            </div>
            <div>
                <div className='row text-ribbon'>
                    <div className='col-md-3 col-xl-2 aos-init' data-aos="fade-up" data-aos-delay="150">
                        <div className='d-flex align-items-center justify-content-center rowWrapper'>
                            {/* <div className='parent'>G</div> */}
                            <div className='d-flex flex-column ms-1'>
                                <div className='child1'>
                                    Generational
                                </div>
                                <div className='child1'>
                                    Excellence
                                </div>
                            </div>
                        </div>
                        <div className='text'>
                            Over 68 years
                            in gears and
                            gearboxes.
                        </div>
                    </div>
                    <div className='col-md-3 col-xl-2 aos-init' data-aos="fade-up" data-aos-delay="150">
                        <div className='d-flex align-items-center justify-content-center rowWrapper'>
                            {/* <div className='parent'>E</div> */}
                            <div className='d-flex flex-column ms-1'>
                                <div className='child1'>
                                    Engineering
                                </div>
                                <div className='child1'>
                                    Expertise
                                </div>
                            </div>
                        </div>
                        <div className='text'>
                            A highly qualified
                            team and advanced
                            technology.
                        </div>
                    </div>
                    <div className='col-md-3 col-xl-2 aos-init' data-aos="fade-up" data-aos-delay="150">
                        <div className='d-flex align-items-center justify-content-center rowWrapper'>
                            {/* <div className='parent'>A</div> */}
                            <div className='d-flex flex-column ms-1'>
                                <div className='child1'>
                                    Aesthetic
                                </div>
                                <div className='child1'>
                                    Design
                                </div>
                            </div>
                        </div>
                        <div className='text'>
                            Functionality meets
                            beauty.
                        </div>
                    </div>
                    <div className='col-md-3 col-xl-2 aos-init' data-aos="fade-up" data-aos-delay="150">
                        <div className='d-flex align-items-center justify-content-center rowWrapper'>
                            {/* <div className='parent'>R</div> */}
                            <div className='d-flex flex-column ms-1'>
                                <div className='child1'>
                                    Remarkable
                                </div>
                                <div className='child1'>
                                    Quality
                                </div>
                            </div>
                        </div>
                        <div className='text'>
                            Durability for a
                            lifetime. </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
