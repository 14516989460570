import React, { useEffect } from 'react';
import './styles/style.scss';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import ContactUs from './pages/ContactUs';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ROUTES from './components/Navbar/routes';

const App = () => {

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, [])

  const { HOME, ABOUTUS, PRODUCTS, CONTACTUS } = ROUTES;

  return (
    <div>
      <Router>
        <Routes>
          <Route path={HOME} element={<Home />} />
          <Route path={ABOUTUS} element={<AboutUs />} />
          <Route path={PRODUCTS} element={<Products />} />
          <Route path={CONTACTUS} element={<ContactUs />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App