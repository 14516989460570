import React, { useState } from 'react';
import axios from 'axios';

const Contant = () => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [b_835d0cb35c0c4a5b203fb01a1_7e01e65507, setB_835d0cb35c0c4a5b203fb01a1_7e01e65507] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowAlert(true);
        try {
            const formData = new FormData();
            formData.append('EMAIL', email);
            formData.append('FNAME', firstName);
            formData.append('LNAME', lastName);
            formData.append('PHONE', phone);
            formData.append('MMERGE6', message);
            formData.append('tags', 216)
            formData.append('b_835d0cb35c0c4a5b203fb01a1_7e01e65507', b_835d0cb35c0c4a5b203fb01a1_7e01e65507);
            await axios.post(
                'https://gmail.us22.list-manage.com/subscribe/post?u=835d0cb35c0c4a5b203fb01a1&amp;id=7e01e65507&amp;f_id=00a5c2e1f0',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
        }
        catch (error) {
            console.log(error);
        }

    }






    return (
        <section className="contact" id='contact'>
            <div className='text-container'>
                <h1 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Get quote</h1>
                <h3 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">Ready to Gear Up? Let’s Get Started!.</h3>
                <p data-aos="fade-up" data-aos-delay="150" className='aos-init'>
                    Are you interested in Mehacon's gear solutions? Whether it's for standard products or custom-built gearboxes, getting a quote is just a few clicks away. Fill in your details and requirements, and our team will get back to you with a tailored solution that meets your specific needs.
                </p>
                <div id="mc_embed_shell">
                    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
                    <div id="mc_embed_signup">
                        <form onSubmit={handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                            <div id="mc_embed_signup_scroll">
                                <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                    <input
                                        type="email"
                                        name="EMAIL"
                                        className="required email"
                                        id="mce-EMAIL"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-FNAME">First Name </label>
                                    <input
                                        type="text"
                                        name="FNAME"
                                        className="text"
                                        id="mce-FNAME"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-LNAME">Last Name </label>
                                    <input
                                        type="text"
                                        name="LNAME"
                                        className="text"
                                        id="mce-LNAME"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-PHONE">Phone Number </label>
                                    <input
                                        type="text"
                                        name="PHONE"
                                        className="REQ_CSS"
                                        id="mce-PHONE"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE6">Message</label>
                                    <textarea
                                        type="text"
                                        name="MMERGE6"
                                        className="text"
                                        id="mce-MMERGE6"
                                        rows={4}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </div>
                                <div id="mce-responses" className="clear foot">
                                    <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                                    <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                                </div>
                                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                                    <input type="text" name="b_835d0cb35c0c4a5b203fb01a1_7e01e65507" tabIndex="-1" value=""
                                        onChange={(e) => setB_835d0cb35c0c4a5b203fb01a1_7e01e65507(e.target.value)}
                                    />
                                </div>
                                {showAlert &&
                                    <div class="mt-25 alert alert-success" role="alert" style={{ width: '96%' }}>
                                        Thank you for contacting us!  We will get back to you soon.
                                    </div>
                                }
                                <div className="optionalParent">
                                    <button type="submit" className="btn btn-primary m-0" name="subscribe" id="mc-embedded-subscribe" >Contact us</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contant;
