import React from 'react';
import Image1 from '../../assets/img/what-we-do/1.jpg';
import Image2 from '../../assets/img/what-we-do/2.jpg';
import Image3 from '../../assets/img/what-we-do/3.jpg';

const WhatWeDo = () => {
    const images = [{
        id: 1,
        text1: 'MEHACON Worm Reduction Gearboxes',
        text2: 'The backbone of industrial applications. Designed for extreme conditions, they deliver consistent performance across various industries.',
        src: Image1
    }, {
        id: 2,
        text1: 'MEHACON Helical Reduction Gearboxes',
        text2: 'Engineered for excellence, these gearboxes offer versatile solutions for demanding applications in single, double, and triple reduction configurations.',
        src: Image2
    }, {
        id: 3,
        text1: 'MEHACON Gear Products',
        text2: 'Robust, reliable, versatile. Our gears power a range of industrial applications, tailored to withstand challenging environments.',
        src: Image3
    }];

    return (
        <section className="what-we-do" id='products'>
            <div className='text-container'>
                <h2 data-aos="fade-up" data-aos-delay="150" className="text-primary aos-init">What we do</h2>
            </div>
            <div className='image-container row justify-content-center aos-init' data-aos="fade-up" data-aos-delay="150">
                {
                    images.map((image, index) => (
                        <div key={index} className='col-md-4 pd-4 aos-init d-flex flex-column' data-aos="fade-up" data-aos-delay="150">
                            <img key={index} src={image.src} alt='' />
                            <p className='text1'>
                                {image.text1}
                            </p>
                            <p className='text2'>
                                {image.text2}
                            </p>
                        </div>
                    ))
                }
            </div>
        </section>
    );
};

export default WhatWeDo;
