import React from 'react';
import { ReactComponent as FooterName } from '../../assets/img/FooterName.svg';
import { ReactComponent as Logo } from '../../assets/img/Logo.svg';

const withFooter = (WrappedComponent) => {
    return (props) => {
        return (
            <div>
                <WrappedComponent {...props} />
                <div className='mt-5 footer bg-primary justify-content-between d-flex'>
                    <div className='logo1'>
                        <Logo />
                    </div>
                    <div className='logo2'>
                        <FooterName />
                    </div>
                </div>
            </div>
        );
    };
};

export default withFooter;
